<template>
  <VTradeProcedure
    :url-action-query="`${EXPAND_LOT}&search[Active]=active`"
    :is-chosen-icon="false"
    is-checkbox
    :touch-bar="touchBarDelete"
    class-toggle-display="my-trade__toggle-display--admin"
  />
</template>
<script>
import { EXPAND_LOT } from '@/utils/consts'
import VTradeProcedure from '@/components/views/TradeProcedure/VTradeProcedure'
import { touchBarDelete } from '@/utils/touchbar'
export default {
  setup () {
    return {
      EXPAND_LOT,
      touchBarDelete
    }
  },
  components: { VTradeProcedure }
}
</script>
